<template>
  <div class="page-wrapper">
    <Loading v-if="loading"></Loading>
    <div class="page-banner bg" :style="{ 'background-image': 'url(' + banner + ')' }">
      <div class="container">
        <div class="page-name common-padding">
          <h1>{{ title }}</h1>
        </div>
      </div>
    </div>

    <div class="serve">
      <div class="container common-padding">
        <div class="content">
          <div v-html="editorDescription" class="editor-li"></div>
          <div class="s-imgList">
              <ImgViewer :imgList="images"></ImgViewer>
          </div>
        </div>
      </div>
    </div>


    <div class="common-btn">
      <router-link to="/contact">
        <div class="common-btn-txt">
          {{ $t("quote") }}
        </div>
      </router-link>
    </div>

    <Copyright></Copyright>

</div>
</template>

<script>
import Copyright from "@/components/Copyright";
import ImgViewer from "@/components/imgViewer";
import Loading from "@/components/loading";
import {getServiceDetails} from "@/network/main";

export default {
  name: "Solution",
  components:{
    Copyright,
    ImgViewer,
    Loading,
  },
  data() {
    return{
      loading:true,
      id:null,
      banner:'',
      title:'',
      content:'',
      images: [],
    }
  },
  created() {
    this.id = this.$route.params.id; //拿到id
    getServiceDetails(this.id).then(res =>{
      this.loading = false;
      const data = res.data.data;
      this.banner = data.banner.details_banner;
      this.title = data.details.title;
      this.content = data.details.details;
      this.images = data.details.images
    })
  },
  computed: {
    editorDescription() {
      if (this.content) {
        return this.content.replace(/\r?\n/g, "<br>");
      }
      return "";
    },
  },
}
</script>

<style scoped>
.serve{
  background-color: rgb(203, 233, 196);
}
.s-imgList{
  padding: 30px 0;
}
.s-imgList ul{
  display: flex;
  flex-wrap: wrap;
}
.s-imgList ul li{
  width: 32%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.s-imgList ul li img{
    max-height: 370px;
}
.common-btn{
  margin-bottom: 30px;
}

.editor-li ul{
  padding-left: 20px;
}
.editor-li ::v-deep ul{
  padding-left: 20px!important;
}

.editor-li ::v-deep ul li {
  list-style: disc!important;
}
/*//::v-deep//穿透作用域限制*!*/
@media only screen and (min-width:0px) and (max-width:767px){
  .s-imgList ul li{
    width: 100%;
  }
}

</style>