<template>
<div class="events-img">
  <!--  v-viewer可以自定义一些Options是否显示,false是不显示    -->
  <div class="images" v-viewer="{movable: false,
      navbar: false, title: false, tooltip: false,
      movable: false, zoomable: false, rotatable: false,
      scalable:false}">
<!--    <img v-for="src in list" :key="src" :src="src.image" class="image-location">-->
    <img v-for="src in imgList" :key="src" :src="src" class="image-location">
  </div>
</div>
</template>

<script>
import { defineComponent } from 'vue';
export default  defineComponent ({
  name: "eventListItem",
  props: {
    imgList: {
        Array,
        default() {
          return [];
        }
      }
    },
})
</script>

<style scoped>
.events-img{
  margin-bottom: 20px;
}
.image-location{
  /*width:10%;*/
  max-height: 300px;
  cursor: pointer;
  display: inline-block;
  padding: 10px;
}
.image-location:hover{
  transition: ease 0.4s;
  transform: scale(1.05);
}
@media only screen and (min-width:0) and (max-width:767px){
   .image-location{
     width:30%;
     padding: 10px;
   }
}
</style>